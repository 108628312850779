<template>
      <div class="w-full border-4 border-dark rounded-xl overflow-hidden flex flex-wrap hotel-card">
        <div class="w-full md:w-1/2 ">
          <DaySwiper
            imgClass=""
            :images="images"
          ></DaySwiper>
        </div>
        <div class="w-full md:w-1/2 ">
          <div class="p-6 md:p-16">
              <h3 class="font-secondary text-2xl md:text-3xl mb-2">{{hotel.VoucherName}}</h3>
              <p class="uppercase text-primary text-md mb-4" v-if="hotel.description || hotel.comment">
                  (<span v-if="hotel.description">{{hotel.description}}</span>
                  <span v-if="hotel.description && hotel.comment">&nbsp;</span>  
                  <span v-if="hotel.comment">{{hotel.comment}}</span>)
              </p>
              <span class="text-dark" v-html="hotel.SupplierDescription"></span>
          </div>
        </div>
        
      </div>
</template>

<script>
//import * as _ from 'lodash';
const s3 = process.env.VUE_APP_S3;
import DaySwiper from '@/components/itinerary/DaySwiper.vue';
export default {
  name: 'SayHotel',
  props: {
    hotel: Object,
  },
  components: {
    DaySwiper
  },
  data () {
    return {
      images: []
    }
  },
  async mounted () {
    this.images = [];
      for (let i=0; i<3; i++) {
      let img = `${s3}/OPT_ID.${this.hotel.opt_id}.${i}.png`;
      this.checkImage(img, (exist) => {
        if (exist) this.images.push({url: img});
      });
    }
  },
  methods: {
    async checkImage (url, callback) {
      const img = new Image();
      img.onload = () => callback(true);
      img.onerror = () => callback(false);
      img.src = url;
    }
  }
}
</script>

<style scoped>
  </style>
