<template>
    <section class="bg-white py-6 md:py-12">
      <div class="max-w-screen-2xl mx-auto px-4 flex flex-wrap py-12 my-6 justify-center">

        <div class="w-full md:w-1/2 md:pr-20 flex justify-between flex-col">
            <h2 class="font-secondary text-2xl md:text-3xl mb-8">
            
            {{tr('This is a Carbon Neutral Trip')}}
          </h2>
          <p class="font-normal ">{{tr('_carbon1')}}</p>

         <p class="font-normal mt-4"><b class="font-bold">{{tr('_carbon2')}}</b> {{tr('_carbon3')}}</p>

         <p class="font-normal mt-4">{{tr('_carbon4')}} 
            <a :href="tr('_carbon_link')" class="font-bold uppercase underline" target="_blank">{{tr('HERE')}}</a>.</p>
        
          <img src="@/assets/trip/img/logostira.svg" class="mt-20 md:w-10/12">
        </div>

        <div class="w-full md:w-1/2 md:pr-20 mt-12   md:mt-0 relative">

          <div class="rounded-xl overflow-hidden  flex justify-center items-center">
            <img src="@/assets/trip/img/arboles.png" class='object-center   object-cover'>
            <div class="text-center absolute z-10 flex flex-col">
                <img src="@/assets/trip/img/tree.svg" class="mb-3 mx-auto">
                <span class="text-primary font-secondary text-5xl mb-3">66,857</span>
                <span class="text-white text-lg font-normal mb-3">{{tr('The road to plant 100,000 trees')}}</span>

                <svg class="mx-auto" width="65" height="15" viewBox="0 0 65 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M47.2483 -0.000488281L64.3579 10.1201L61.7364 14.8689L47.1438 6.23717L32.8546 14.0222L18.5354 6.22085L3.1722 14.8953L0.64209 10.0937L18.4908 0.0158283L32.8546 7.84147L47.2483 -0.000488281Z" fill="white"/>
                </svg>
            </div>
          </div>
        </div>
      </div>

    </section>

</template>
<script>
export default {
  name: 'CarbonNeutral',
  props: {
  },
  data () {
    return {
      booking: null
    }
  },
  methods: {
    tr(key) {
      return this.$root.tr(key);
    }
  }
}
</script>
