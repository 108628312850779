<template>
  <section class="bg-light-gray py-6 md:py-12">
    <div class="max-w-screen-2xl mx-auto px-4 flex flex-wrap py-12 my-6 justify-center">
      <div class="w-full  md:pr-20">
          <h2 class="font-secondary text-2xl md:text-3xl mb-8">
        {{tr('FAQs')}}
        </h2>
      </div>
      <div class="mt-6 md:mt-8 w-full flex flex-wrap">
          <div id="accordion-collapse-faq" data-active-classes="text-primary" data-accordion="collapse" class="grid md:grid-cols-2 gap-5 w-full">

            <div v-for="(item, index) in items" :key="item.id" class="w-full">
                <div class="border border-dark rounded-lg overflow-hidden">
                  <h2 :id="'accordion-collapse-heading-faq-' + item.id">
                    <button type="button" class="flex items-center bg-white justify-between w-full p-5 font-medium rtl:text-right gap-4"  
                      @click="toggle(index)"
                      :class="{'text-dark': activeIndex != index, 'text-primary': activeIndex === index}"
                      :aria-expanded="activeIndex === index" 
                      :aria-controls="'accordion-collapse-body-' + item.id">
                      <span class="text-lg hover:text-primary font-bold">{{ item.title }}</span>
                      <svg :class="{'rotate-180': activeIndex === index}" data-accordion-icon class="w-3 h-3 shrink-0" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
                        <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5 5 1 1 5"/>
                      </svg>
                  </button>
                  </h2>
                  <div :id="'accordion-collapse-heading-body-' + item.id" :class="{'hidden': activeIndex != index}" 
                    :aria-labelledby="'accordion-collapse-body-faq-faq-' + item.id" v-show="activeIndex === index">
                    <div class="p-5 pt-0  bg-white ">
                      <p class="mb-2 text-dark">{{ item.content }}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
      </div>
  </section>
</template>

<script>
export default {
name: 'FAQsa',
methods: {
    toggle(index) {
        this.activeIndex = this.activeIndex === index ? null : index;
    },
    tr(key) {
      return this.$root.tr(key);
    }
},
data() {
    return {
        activeIndex: null,
        items: [
          { id: 1, title: this.tr('_FAQsa1'), 
            content: this.tr('_FAQsaAnswer1')
          },
          { id: 2, title: this.tr('_FAQsa2'), 
            content: this.tr('_FAQsaAnswer2')
          },
          { id: 3, title: this.tr('_FAQsa3'),
            content: this.tr('_FAQsaAnswer3')
          },
          { id: 4, title: this.tr('_FAQsa4'), 
            content: this.tr('_FAQsaAnswer4')
          }
        ]      
    };
  },
}
</script>
